<template>
    <v-dialog
    v-model="show"
    persistent
    width="300">
   
    <v-card color="light_gray">
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-cash</v-icon><span class="va-underline">Convert Commision</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text class="pa-4 def-font">
               <v-form
                ref="form"
                v-model="valid"
                autocomplete="off"
                lazy-validation>
                <v-text-field 
                    v-if="account.comm_amount > 0"
                    type="number"
                    v-model="entry.amount"
                    label="Enter Amount"
                    dense
                    outlined
                    :rules="nameRules"
                    required
                /> 
                <span v-else>Not Applicable</span>
               </v-form>
               <v-divider/>
          </v-card-text>
          <v-card-actions>
              <v-spacer/>
              <v-btn small color="secondary" @click="validate()"  v-if="account.comm_amount > 0">
                 Convert
              </v-btn>
          </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
      show:{
          type: Boolean,
          default: false
      },
      account: {
          type: Object,
          default: ()=>{return {}}
      } 
  },
  data () {
      return {
          valid: true,
          entry:{},
           nameRules: [
            v => !!v || '*Required'
        ],
      }
    },
  watch: {
     show(val) {
         if(val) {
             this.entry = {}
         }
     }
  },
  computed:{
   
  },
  methods: {
   emitToParent (action) {
      this.$emit('DialogEvent',action)
    },
    validate(){
        if (this.$refs.form.validate()) {
            if(this.account.comm_amount > 0 && this.account.comm_amount >= this.$IsNum(this.entry.amount)) {
                this.Load()
            } else {
                this.VA_ALERT("error", "Insuficient commission!")
            }
        }
    },
    Load(){
        this.entry.type = "Commission to load"
        this.entry.description = "Convert commision to load"
        this.entry.amount = this.$IsNum(this.entry.amount)
        this.entry.account = this.account.id
           this.$emit('DialogEvent',this.entry)
    }
  }
}
</script>
