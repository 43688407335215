<template>
    <v-dialog
    v-model="show"
    persistent
    width="300">
   
    <v-card color="light_gray">
        <v-toolbar class="mid-font" color="secondary" dense dark >
            <span class="va-underline">{{header}}</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text class="pa-4 def-font">
               <v-form
                ref="form"
                v-model="valid"
                lazy-validation>
                    <v-text-field
                        v-model="textval"
                        dense
                        label="Please enter text"
                        outlined
                        :rules="nameRules"
                        required
                    />
               </v-form>
          </v-card-text>
          <v-card-actions>
              <v-spacer/>
             <v-btn small color="success" @click="validate()">OK</v-btn>
          </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
export default {
    mixins: [API_MIXINS],
  props: {
      show:{
          type: Boolean,
          default: false
      },
      type: {
           type: String,
          default: ''
      },
      header: {
           type: String,
          default: ''
      }
  },
   data () {
      return {
          textval: '',
          valid: true,
           nameRules: [
            v => !!v || '*Required'
        ],
      }
    },
  watch: {
      show(val) {
          if(!val) {
              this.textval = ''
          }
      }
  },
  computed:{
  },
  methods: {
   emitToParent (action) {
      this.$emit('DialogEvent', {action:action})
    },
     validate(){
        if (this.$refs.form.validate()) {
            this.$emit('DialogEvent', {action:this.textval})
        }
    },
  }
}
</script>
