<template>
    <v-toolbar flat dense  color="light" id="va-toolbar" class="hidden-print-only">
      <v-app-bar-nav-icon @click="togleDrawer()" v-if="$store.state.responsive"></v-app-bar-nav-icon>

      <v-toolbar-title class="mediium-font">Currently in <span class="text-h4">{{GAMENAME}} </span></v-toolbar-title>
      <v-spacer></v-spacer>
      <span class="small-font">Hi, {{$session.get('fullname')}}</span>
      <v-icon small right color="error" @click="confirm=true">mdi-power-standby</v-icon>

      <v-dialog
      v-model="confirm"
      persistent
      max-width="290">
      <v-card color="secondary" dark>
        <v-card-text class="pt-5">Would you like to log out?</v-card-text>
        <v-card-actions>
         
          <v-btn
            color="warning darken-1"
            text
            @click="confirm = false">Cancel</v-btn>
             <v-spacer/>
          <v-btn
            color="green darken-1"
            text
            @click="confirm = false, logout()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-toolbar>
</template>

<script>
import {
  mapMutations
} from 'vuex'
export default {
    data: () => ({
    confirm: false,
    title: null
  }),

  computed:{
    currentRouteName() {
        return this.$route.name;
    },
    GAMENAME() {
      return this.$route.name;
    }
    
  },
  methods: {
    ...mapMutations(['setDrawer']),
    togleDrawer () {
      this.setDrawer(true)
    },
    logout() {
      this.$router.push('/login')
    }
  }
}
</script>
 