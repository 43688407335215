<template>
    <v-dialog
    v-model="show"
    persistent
    width="600">
   
    <v-card color="secondary" dark>
        
          <v-card-text class="pa-4 ">
              <div class="ma-10 text-h4 text-center">
                    <p>Confirm Result?</p>
                     {{message}}
              </div>
          </v-card-text>
          <v-card-actions>
              <v-btn large color="error" text @click="emitToParent('close')">
                  {{cancelbtn}}
              </v-btn>
              <v-spacer/>
              <v-btn large color="success" text @click="emitToParent('ok')">
                  {{okbtn}}
              </v-btn>
          </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
      show:{
          type: Boolean,
          default: false
      },
      message: {
            type: String,
          default:''
      },
      okbtn: {
          type: String,
          default:''
      },
      cancelbtn: {
          type: String,
          default:''
      }
  },
  methods: {
   emitToParent (action) {
      this.$emit('ConfirmEvent', action)
    }
  }
}
</script>
