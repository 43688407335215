<template>
    <v-dialog
    v-model="show"
    persistent
    width="400">
   
    <v-card color="light_gray">
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-ticket</v-icon><span class="va-underline">Cashout</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text class="pa-4 def-font">
           
            <v-alert
                text
              >
                <h3 class="text-h6">
                    Receivable: {{$money(cashout.total)}}
                </h3>
               <div>
                  Request Amount: {{$money(cashout.amount)}} <br/>
                  Convenience: {{$money(cashout.fee)}}<br/>
                  Receivable: {{$money(cashout.total)}}<br/>
                  GCASH Account Name: {{cashout.gcash_name}}<br/>
                  GCASH Number: {{cashout.gcash}}

               </div>
            </v-alert>
               <v-form
                ref="form"
                v-model="valid"
                lazy-validation>
               
                <v-select
                 v-model="cashout.status" 
                 label="Status"
                 :rules="nameRules"
                    required
                 outlined
                 :items="['Pending','Cancelled', 'Completed']"/>
                 <v-select
                    v-model="cashout.remarks"
                    label="Add Remarks"
                    outlined
                    :items="reasons"/>
                 
                
               </v-form>
               <v-divider/>
          </v-card-text>
          <v-card-actions>
               <v-btn small color="warning" @click="emitToParent('close')">
                 Close
              </v-btn>
              <v-spacer/>
              <v-btn small color="error" @click="validate()">
                  Save
              </v-btn>
          </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
export default {
    mixins: [API_MIXINS],
  props: {
      show:{
          type: Boolean,
          default: false
      },
      cashout:{
          type: Object,
          default: ()=>{}
      }
  },
  data () {
      return {
        reasons: [
        "",
            "Invalid Gcash account. Please cancel this request and try again.",
            "Service Unavailable. Please cancel this request and try again later.",
            "Insuficient balance."
        ],
          valid: true,
           nameRules: [
            v => !!v || '*Required'
        ],
      }
    },
  watch: {
     show(val) {
         if(val) {
             this.entry = {}
         }
     }
  },
  computed:{
   
  },
  methods: {
    validate () {
    if (this.$refs.form.validate()) {
        this.update()
    }
    },
   emitToParent (action) {
      this.$emit('DialogEvent',action )
    },
    update(){
         this.$http.post("admin_update_cashout",this.cashout).then(response => {
             console.log(response.data)
              if(!response.data.status) {
                this.VA_ALERT('error', (response.data.message))
              } else {
                this.VA_ALERT('success', (response.data.message))
              }
                 this.$emit('DialogEvent', "close")
          }).catch(e => {
            console.log(e.data)
          })
    }
  }
}
</script>
