<template>
 <v-dialog
    v-model="show"
    persistent
    width="320">
   
    <v-card dense>
        <v-toolbar dense>
            Combinations
            <v-spacer/>
               <v-btn text fab @click="emitToParent()"><v-icon>mdi-close</v-icon></v-btn>
           
        </v-toolbar>
        <v-card-text>
            <v-form
            class="mt-5"
            autocomplete="off"
            >
            <p>
                <v-text-field 
                v-model="search"
                dense
                outlined
                label="Search combination"/>
            </p>
            </v-form>
            <table class="va-table def-font" id="over-table">
                <tr>
                    <!-- <th>DRAW ID</th> -->
                    <th>COMBINATIONS</th>
                    <!-- <th>STATUS</th> -->
                    <th class="text-right">BET AMOUNT</th>
                 
                </tr>
                 <tr v-for="(item,index) in FILTERED" :key="index" >
                     <!-- <td class="border_bot">{{item.draw_id}} </td> -->
                    <td class="border_bot">{{item.combination}} </td>
                     <td class="border_bot text-right">{{item.amount}} </td>
                   
                </tr>
               
               
            </table>
                <v-alert
                dense
                v-if="FILTERED.length == 0"
                small
                type="info"
                >
                No Bet on this combinations
                </v-alert>
           
        </v-card-text>     
    </v-card>
 </v-dialog>
</template>

<script>

export default {
  props: {
      show:{
          type: Boolean,
          default: false
      },
      combinations: {
           type: Array,
          default: ()=>[]
      },
  },
  data: () => ({
        search: "",
    }),
    computed:{
        FILTERED(){
           
            if(this.search.trim()=='') {
                 return this.combinations
            }
             var found = this.combinations.reduce((res, item) => {
                 if(item.combination.search(this.search) != -1) {
                        res.push(item)
                 }
                 return res
             },[])

            return found
           
        }
    },
  methods: {
      emitToParent (action) {
      this.$emit('DialogEvent', action)
    }
  }
}
</script>
