<template>
    <v-dialog
    v-model="show"
    persistent
    width="480">
   
    <v-card color="light_gray">
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-map-marker-radius</v-icon><span class="va-underline">{{state=='new'?'New':'Edit'}} Game</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text class="pa-4 def-font">
               <v-form
                ref="form"
                v-model="valid"
                lazy-validation>
                <v-text-field 
                    v-model="entry.name"
                    label="Game Name"
                    dense
                    outlined
                    :rules="nameRules"
                    required
                />

                 <v-text-field 
                    v-model="entry.code"
                    label="Game Code"
                    dense
                    outlined
                    :rules="nameRules"
                    required
                />
                 <v-autocomplete
                    v-model="entry.type"
                    label="Game type"
                    :items="['National', 'Local']"
                    dense
                    outlined
                    :rules="nameRules"
                    required
                />

                <v-text-field 
                    v-model="entry.winning_amount"
                    label="Win amount per unit"
                    dense
                    outlined
                    :rules="nameRules"
                    required
                />
                 <v-text-field 
                    v-model="entry.payout_percent"
                    label="Payout Percentage"
                    dense
                    outlined
                    :rules="nameRules"
                    required
                />
                 <v-text-field 
                    v-model="entry.fund"
                    label="Funding"
                    dense
                    outlined
                    :rules="nameRules"
                    required
                />
                  <v-autocomplete
                    v-model="entry.status"
                    label="Status"
                    :items="['Active', 'Inactive']"
                    dense
                    outlined
                    :rules="nameRules"
                    required
                />
                <p>Draw Times <small color="warning">(In sequence)</small></p>
                <div class="pa-2">
                <v-chip small class="def-font ma-1" v-for="(item,index) in draws" :key="index+'-d'" color="info"><v-icon @click="draws.splice(index,1)" left x-small>mdi-delete</v-icon> {{item}}</v-chip>
                </div>
                <v-text-field 
                    v-model="draw_time"
                    label="Draw Time"
                    dense
                    @change="draw_time!=''?draws.push(draw_time):draw_time='', draw_time=''"
                    outlined
                    persistent-hint
                    hint="Format Sample (11:00 PM)"
                />

               </v-form>
               <v-divider/>
          </v-card-text>
          <v-card-actions>
              <!-- <v-btn small color="error" v-if="state=='update'" @click="Remove()">
                  Remove
              </v-btn> -->
              <v-spacer/>
              <v-btn small color="secondary" @click="validate()">
                  {{state=='new'?'ADD':'SAVE'}} 
              </v-btn>
          </v-card-actions>
    </v-card>
  
  </v-dialog>
</template>

<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
export default {
    mixins: [API_MIXINS],
  props: {
      show:{
          type: Boolean,
          default: false
      },
      state: {
          type: String,
          default: 'new'
      },
      game: {
          type: Object,
          default:()=>{}
      }
  },
  data () {
      return {
          draws:[],
          draw_time:'',
          textform: false,
          action_type: '',
          text_header:"",
          valid: true,
          municipal:null,
          entry:{},
           nameRules: [
            v => !!v || '*Required'
        ],
      }
    },
  watch: {
     show(val) {
         if(val) {
             if(this.state!='new'){
                 this.entry = this.game
                 this.draws = this.game.Draws !=null?this.game.Draws:this.draws=[]
             } 
         } else { 
             this.entry = {}
             this.draw_time=''
     }
     }
  },
  computed:{
    
  },
  methods: {
   emitToParent (action) {
      this.$emit('DialogEvent', action)
    },
   
    validate(){
        if (this.$refs.form.validate()) {
         this.entry.Draws = this.draws
         console.log(this.entry)
         this.Save()
        }
    },
    Save(){
        var method = this.state == 'new'?'game/add':'game/update'
        this.$http.post(method, this.entry).then(response => {
            response.data.status?this.VA_ALERT('success',response.data.message):this.VA_ALERT('error',response.data.message)
        this.$emit('DialogEvent', 'close')
        }).catch(e => {
            console.log(e)
        })
    }
  }
}
</script>
