<template>
    <v-dialog
    v-model="show"
    persistent
    width="400">
   
    <v-card>
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-ticket-confirmation</v-icon><span class="va-underline">Winning Ticket</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
        <v-card-text class="pa-5">
              
               <v-form
                ref="form"
                v-model="valid"
                lazy-validation>
                        <v-text-field
                             type="number"
                                 v-model="ticket_number"
                                label="Enter ticket number"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                            <v-text-field
                                 v-model="ticket_code"
                                label="Enter ticket code"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
               </v-form>
               <template v-if="winning_ticket.length > 0">
                   <v-card dark color="secondary" class="pa-2">
                            <v-row no-gutters>
                            Draw ID: 
                            <v-spacer/>
                            {{winning_ticket[0].draw_id}}
                            </v-row>
                            <v-divider/>
                            <v-row no-gutters>
                            Amount: 
                            <v-spacer/>
                            {{$money(winning_ticket[0].amount)}}
                            </v-row>
                            <v-divider/>
                            <v-row no-gutters>
                                Winning: 
                                <v-spacer/>
                                {{$money($IsNum(winning_ticket[0].amount_payout))}}
                            </v-row>
                            <v-divider/>
                            <v-row no-gutters>
                                Status: 
                                 <v-spacer/>
                                {{winning_ticket[0].status}}
                             </v-row>
                            <v-divider/>
                            <v-card-actions>
                                    <v-btn text small color="warning" @click="getTicket()">
                                        view ticket
                                    </v-btn>
                                    <v-spacer/>
                                    <v-btn small text color="success" v-if="winning_ticket[0].status !='claimed'" @click="claimTicket()">
                                        Claim
                                    </v-btn>
                            </v-card-actions>
                     </v-card>
               </template>
               

        </v-card-text> 
         <v-card-actions>
              <v-btn text small color="warning" @click="emitToParent('close')">
                 close
              </v-btn>
              <v-spacer/>
              <v-btn small text color="success" @click="validate()">
                 Validate
              </v-btn>
          </v-card-actions>
    </v-card>
    <game-ticket :show="show_ticket" :ticket="ticket" @DialogEvent="tfEvent"/>
  </v-dialog>
</template>

<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
export default {
    mixins: [API_MIXINS],
  props: {
      show:{
          type: Boolean,
          default: false
      }
  },
  data () {
      return {
          show_ticket: false,
          ticket_number: null,
          ticket_code: '',
          valid: true,
           nameRules: [
            v => !!v || '*Required'
        ],
        ticket: {},
        winning_ticket:[]
      }
    },
    watch:{
        show(val) {
            if(val) {
                this.winning_ticket = []
                this.ticket_number = null
                this.ticket_code = ''
            }
        }
    },
  computed:{
     
  },
  methods: {
      tfEvent(){
          this.show_ticket = false
      },
    validate(){
        if (this.$refs.form.validate()) {
            this.validateWinning()
        }
    },
   emitToParent (action) {
      this.$emit('DialogEvent', action)
    },
    validateWinning() {
        this.$http.post("claim/validate_ticket", {id: this.$IsNum(this.ticket_number), code: this.ticket_code}).then(response => {
            response.data.winning_ticket != null?this.VA_ALERT('success',response.data.message):this.VA_ALERT('error',"Not a winning ticket!")
            response.data.winning_ticket != null?this.winning_ticket = response.data.winning_ticket:this.winning_ticket = []
        }).catch(e => {
            console.log(e)
        })
    },
    getTicket() {
        this.$http.post("ticket/get", {id: this.$IsNum(this.ticket_number), code: this.ticket_code}).then(response => {
        response.data.ticket != null?this.ticket = response.data.ticket: this.ticket = {}
        this.show_ticket = true
      
        }).catch(e => {
            console.log(e)
        })
    },
    claimTicket() {
        this.$http.post("/claim/winning_ticket",this.winning_ticket[0]).then(response => {
         response.data.status ?this.VA_ALERT('success',response.data.message):this.VA_ALERT('error',response.data.message)
         setTimeout(()=>{ this.$emit('DialogEvent', 'close')}, 1000)
        }).catch(e => {
            console.log(e)
        })
    }
  }
}
</script>
