<template>
                <v-data-table
                            class="va-table"
                            :headers="headers"
                            :items="data"
                            >
                             <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                            <td><v-chip small dark :color="$StatusColor(item.status)">{{ item.status}}</v-chip></td>
                           <td>{{ item.draw_id}}</td>
                           <td>{{ item.ticket_id}}</td>
                            <td class="text-right">{{ $money(item.amount)}}</td>
                            <td class="text-right">{{ $money(item.amount_payout)}}</td>
                        </tr>
                        </tbody>
                    </template>
                            </v-data-table>
</template>

<script>
export default {
    data: () => ({
        headers: [
         {text:'Status'},
          {text:'Draw ID'},
          {text:'Ticket #'},
          {text:'Win', align:'right',sortable: false },
          {text:'Payout', align:'right', sortable: false}
        ]
    }),
  props: {
      data:{
          type: Array,
          default: ()=>[]
      }
  },
  methods: {
      
  }
}
</script>