<template>
    <div>
         <v-toolbar  dense  class="mb-4" flat color="#efefef">
             <v-spacer/>
               <span style="width: 200px; margin-top: 24px;"> 
                     <v-menu
                        v-model="from_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="From Date"
                                 :value="from_date"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date" no-title scrollable @input="from_menu=false" />
                    </v-menu>
               </span>
                <span style="width: 200px; margin-top: 24px;" class="ml-2"> 
                     <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="To Date"
                                 :value="to_date"
                                 readonly
                                 filled
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, getTrans()" />
                    </v-menu>
               </span>
               <v-icon right color="primary" @click="dlToCsv()">mdi-download</v-icon>
        </v-toolbar>
          <table class="va-table def-font pa-2 mb-4" id="account-trans-table">
                <tr>
                    <th>Date Time</th>
                    <th>Type</th>
                    <th class="text-left">Amount</th>
                   <th class="text-right">Prev. Bal</th>
                    <th class="text-right">Cur. Bal</th>
                </tr>
                 <tr v-for="(item,index) in ITEMS" :key="index" >
                    <td class="border_bot">{{$moment(item.transdt).format("MMM DD, YYYY hh:mm A")}} </td>
                    <td class="border_bot" :class="STATUS_COLOR(item.type)">{{item.description}}</td>
                      <td class="text-left border_bot"><v-icon left small :color="STATUS_ICON(item.type).color">{{STATUS_ICON(item.type).icon}}</v-icon> {{$money(item.amount)}}</td>
                     <td class="text-right border_bot">{{$money(item.prev_balance)}}</td>
                     <td class="text-right border_bot">{{$money(item.cur_balance)}}</td>
                </tr>
            </table>
  </div>
</template>

<script>

export default {
  props: {
      show:{
           type: Boolean,
          default: false
      },
       user: {
          type: Object,
          default: ()=>{return {}}
      } 
  },
  data: () => ({
        transactions:[],
        from_menu: false,
        to_menu: false,
        account:{},
        to_date:'',
        from_date:'',
    }),
    watch:{
        show(v) {
            if(v) {
                var current_date = this.$moment()
                this.from_date= current_date.subtract(7, "days").format("YYYY-MM-DD")
                this.to_date = this.$moment().format("YYYY-MM-DD")
                this.transactions = []
                this.getTrans()
            } else {
                 this.transactions = []
            }
        }
    },
    computed:{
         ITEMS() {
          return this.$store.state.account_transactions
      }
    },
  methods: {
       getTrans(){
         this.GET_ACCOUNT_TRANSACTIONS({account: this.user.id, from_date: this.from_date, to_date: this.to_date})
        },
        dlToCsv(){
            this.DownloadToCsv("account-trans-table", 'Transactions-' + this.from_date + '-to-' + this.to_date)
        }
  }
}
</script>
