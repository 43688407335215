<template>
    <v-dialog
    v-model="show"
    persistent
    width="470">
   
    <v-card color="light_gray">
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-gamepad-circle-up</v-icon><span class="va-underline">{{state=='new'?'Open':'Update'}} {{game}} Draw</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text class="pa-4 def-font">
               <v-form
                ref="form"
                v-model="valid"
                lazy-validation>
                    <v-row>
                        <v-col cols="6">
                            <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                v-model="entry.date"
                                outlined
                                readonly
                                :rules="nameRules"
                                    required
                                dense
                                label="Select Draw Date"
                                append-icon="mdi-calendar"
                                v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="entry.date" no-title @input="menu1 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                               <v-autocomplete
                                v-model="selected_draw"
                                :items="DRAW_SEQ"
                                label="Time"
                                item-text="time"
                                return-object
                                persistent-hint
                                :hint="selected_draw.draw_seq"
                                :rules="nameRules"
                                    required
                                    outlined

                                    dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                             <v-text-field
                             type="number"
                                v-model="entry.winning_amount"
                                label="Wining Amount"
                                filled
                                :disabled="true"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <template v-if="game == 'PICK3'">
                                     <v-autocomplete
                                v-model="entry.payout_percent"
                                :items="lotto"
                                label="Game Result"
                                item-text="name"
                                item-value="num"
                                 :rules="nameRules"
                                persistent-hint
                                    required
                                    outlined
                                    dense
                            ></v-autocomplete>
                            </template>
                            <template v-else>
                             <v-text-field
                                v-model="entry.payout_percent"
                                label="Payout Percentage"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                            </template>
                        </v-col>
                        <v-col cols="6">
                                <v-text-field
                                type="number"
                                v-model="entry.fund"
                                label="Funding"
                                    outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete
                                v-model="entry.status"
                                :items="['Open', 'Close', 'Ready']"
                                label="Status"
                                :rules="nameRules"
                                    required
                                    outlined
                                    dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    New Draw: {{NEXT_DRAW}}
               </v-form>
               <v-divider/>
          </v-card-text>
          <v-card-actions>
              <v-btn text small color="warning" @click="emitToParent('close')">
                 close
              </v-btn>
              <v-spacer/>
              <v-btn small text color="success" @click="validate()">
                 Ok
              </v-btn>
          </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
export default {
  components: {  },
    mixins: [API_MIXINS],
  props: {
      show:{
          type: Boolean,
          default: false
      },
       state: {
          type: String,
          default: 'new'
      },
      game: {
          type: String,
          default: ''
      },
      item: {
          type: Object,
          default:()=>{}
      }
  },
  data () {
      return {
          menu1: false,
          menu2: false,
          selected_draw:{},
          selected_draw_date: '',
          draw_time:'',
          lotto: [{name: "Super lotto 6/45", num: "45" }, {name: "Mega  lotto 6/49", num: "49" },{name: "Grand  lotto 6/55", num: "55" }],
          valid: true,
          entry:{},
           nameRules: [
            v => !!v || '*Required'
        ],
      }
    },
  watch: {
     show(val) {
         if(val) {
             this.entry = {}
            if(this.state == 'new') {
             this.entry.game = this.GAMESETTING.game
             this.entry.status = "Close"
             this.entry.winning_amount = this.GAMESETTING.winning_amount
             this.entry.fund = this.GAMESETTING.fund
             this.entry.payout_percent = this.GAMESETTING.payout_percent
            } else {
                this.entry = this.item
                this.selected_draw = {time: this.entry.time, draw_seq: this.entry.draw_seq}
            }
         }
     }
  },
  computed:{
      DRAW_SEQ(){
          if(this.$store.state.game_setting.Draws == undefined) {
                return []
          }
          var draws = []
          for (var i = 0;i<this.$store.state.game_setting.Draws.length;i++) {
                draws.push({draw_seq:"draw " + (i+1), time:this.$store.state.game_setting.Draws[i]})
          }
          
           return draws
      },
      GAMESETTING(){
          return this.$store.state.game_setting
      },
    NEXT_DRAW() {
        if(!this.entry.date) {
            return 'No date time selected'
        }
        return this.entry.date + ' ' + this.entry.time
    }
  },
  methods: {
   emitToParent (action) {
      this.$emit('DialogEvent', {action:action})
    },
    validate(){
        if (this.$refs.form.validate()) {
            this.Save()
        }
    },
    Save(){
        this.entry.game = this.game
        this.entry.time = this.selected_draw.time
        this.entry.draw_seq = this.selected_draw.draw_seq
        var method = this.state == 'new'?'draw/add':'draw/update'
        console.log(this.entry)
        this.$http.post(method, this.entry).then(response => {
            response.data.status?this.VA_ALERT('success',response.data.message):this.VA_ALERT('error',response.data.message)
        this.$emit('DialogEvent', {action:'close'})
        }).catch(e => {
            console.log(e)
        })
    
    }
  }
}
</script>
