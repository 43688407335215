<template>
                <v-data-table
                            class="va-table"
                            :headers="headers"
                            :items="data"
                            >
                             <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                            <td>{{ item.draw_id}} </td>
                             <td>
                          <!-- <template v-if="item.game == 'LASTTWO'">
                            {{item.result.num1}} - {{item.result.num2}}
                        </template>
                        <template v-if="item.game == 'PICK3'">
                            {{item.result.num1}} - {{item.result.num2}} - {{item.result.num3}} - {{item.result.num4}} - {{item.result.num5}} - {{item.result.num6}}
                        </template>
                         <template v-if="item.game == 'SWER2' || item.game == 'STLPARES' || item.game == 'STLLOCAL'">
                            {{item.result.num1}} - {{item.result.num2}}
                        </template>
                        <template v-if="item.game == '3D'">
                            {{item.result.num1}} - {{item.result.num2}} - {{item.result.num3}}
                        </template>
                         <template v-if="item.game == '3DLOCAL'">
                            {{item.result.num1}} - {{item.result.num2}} - {{item.result.num3}}
                        </template>
                         <template v-if="item.game == '2DLOCAL'">
                            {{item.result.num1}} - {{item.result.num2}}
                        </template>
                          <template v-if="item.game == '2D' || item.game == 'STL-SILAY' || item.game == 'STL-ILOILO-CITY' || item.game == 'STL-ILOILO-PROVINCE' || item.game == 'PERYA-ANTIQUE'">
                            {{item.result.num1}} - {{item.result.num2}} 
                        </template>-->
                       
                        
                            <template v-if="item.game == '3D' || item.game =='3D-STL-ILOILO-CITY'">
                                {{item.result.num1}} - {{item.result.num2}} - {{item.result.num3}}
                            </template>
                            <template v-else-if="item.game == '4D'">
                            {{item.result.num1}} - {{item.result.num2}} -   {{item.result.num3}} - {{item.result.num4}}
                        </template> 
                            <template v-else>
                                {{item.result.num1}} - {{item.result.num2}} 
                            </template>
                             </td>
                            <!-- <td class="text-right">{{ item.num_of_winner}}</td>
                            <td class="text-right">{{ $money(item.amount_of_winning)}}</td>
                            <td class="text-right">{{ $money(item.total_collection)}}</td> 
                            <td class="text-right">{{ $money($IsNum(item.amount_payout))}}</td> -->
                             
                        </tr>
                        </tbody>
                    </template>
                            </v-data-table>
</template>

<script>
export default {
    data: () => ({
        headers: [
           {text:'Draw ID'},
             {text:'Result'},
            // {text:'Winners', align:'right', sortable: false},
            // {text:'Win', align:'right',sortable: false },
            // // {text:'Collections', align:'right', sortable: false},
            //  {text:'Payout', align:'right', sortable: false},
        ]
    }),
  props: {
      data:{
          type: Array,
          default: ()=>[]
      }
  },
  methods: {
      
  }
}
</script>