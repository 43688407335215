<template>
    <v-dialog
    v-model="show"
    persistent
    fullscreen
    >
   
    <v-card >
        <v-toolbar class="mid-font mb-10" color="secondary" dense dark >
            <span class="va-underline"> {{account.id}} - {{account.fullname}} </span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text class="def-font">
                <v-row>
                    <v-col cols="5">
                        <v-card>
                            <v-card-text class="text-center">
                                <div class="text-h4 text-primary">{{$money(account.balance)}}</div>
                                <span>Current Load Balance</span>
                                <br/>
                                <br/>
                                 <div class="text-h4 text-info">{{$money(account.comm_amount)}}</div>
                                <span>Current Commision</span>
                                <div class="mt-10">
                                    <v-btn  color="info" class="ma-1" tile @click="convertform = true">Convert Commision</v-btn>
                                    <v-btn  color="accent" class="ma-1" tile @click="cashform = true">CASH OUT</v-btn>
                                    <v-btn color="success" class="ma-1" tile @click="loadform = true">ADD LOAD</v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="7">
                     <report-transactions :user="account" :show="show"/>
                    </v-col>
                </v-row>
          </v-card-text>
          <v-card-actions>
          </v-card-actions>
          <va-load-form :show="loadform" :account="account" @DialogEvent="LfEvent"/>
           <va-convert-form :show="convertform" :account="account" @DialogEvent="ConfEvent"/>
           <va-cash-out :show="cashform" :account="account" @DialogEvent="CfEvent"/>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
      show:{
          type: Boolean,
          default: false
      },
      user: {
          type: Object,
          default: ()=>{return {}}
      } 
  },
   data () {
      return {
          convertform: false,
          loadform: false,
          cashform: false,
          from_menu: false,
          to_menu: false,
          account:{},
          to_date:'',
          from_date:'',
          textval: '',
          items:[],
          valid: true,
           nameRules: [
            v => !!v || '*Required'
        ],
      }
    },
  watch: {
      show(v) {
          if(v) {
               this.account = this.user
                var current_date = this.$moment()
                this.from_date= current_date.subtract(7, "days").format("YYYY-MM-DD")
              this.to_date = this.$moment().format("YYYY-MM-DD")
               this.userDetails()
              
          } else {
               this.account = {}
          }
      }
  },
  computed:{
      ITEMS() {
          return this.$store.state.account_transactions
      }
  },
  methods: {
   emitToParent (action) {
      this.$emit('DialogEvent', {action:action})
    },
    ConfEvent(e) {
        this.convertform = false
        if(e != "close") {
            this.addLoad(e)
        }
    },
    CfEvent(e) {
        this.cashform = false
        if(e != "close") {
            this.cashOut(e)
        }
    },
    LfEvent(e){
        this.loadform = false
        if(e != "close") {
            this.addLoad(e)
        }
    },
     userDetails(){
          this.$http.post("user/details",{id: this.account.id}).then(response => {
              if(response.data.status) {
                 this.account = response.data.account
                 this.getTrans()
              } 
          }).catch(e => {
            console.log(e.data)
          })
    },
     addLoad(load){
         console.log(load)
          this.$http.post("wallet/load",load).then(response => {
              console.log( response.data.message)
              if(response.data.status) {
                  this.VA_ALERT('success', response.data.message)
                  this.GET_ACCOUNT_TRANSACTIONS({account: this.account.id, from_date: this.from_date, to_date: this.to_date})
                  this.userDetails()
              } else {
                  this.VA_ALERT('error', response.data.message)
              }
          }).catch(e => {
            console.log(e.data)
          })
    },
     cashOut(param){
          this.$http.post("wallet/cashout",param).then(response => {
              if(response.data.status) {
                  this.VA_ALERT('success', response.data.message)
                  this.GET_ACCOUNT_TRANSACTIONS({account: this.account.id, from_date: this.from_date, to_date: this.to_date})
                   this.userDetails()
              } else {
                  this.VA_ALERT('error', response.data.message)
              }
          }).catch(e => {
            console.log(e.data)
          })
    },
    dlToCsv() {
         this.DownloadToCsv ("account-trans-table", this.from_date+"-"+this.to_date)
    }
  }
}
</script>
