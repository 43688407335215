import countries from './country'
export default {
  countries: countries,
  drawer: true, 
  responsive: false,
  systemlogo: '../logo-int.png',
  game_logo:{
    three_num: '../3d.png',
    two_num: '../2d.png',
    four_num: '../4d.png',
  },
  game_setting:{},
  loginbg: '../bg.png',
  applogo: '../logo-int.png',
  is_logged: false,
  show_alert: false,
  overlay: false,
  alert_color: "secondary",
  alert_text: "No message",
  allow_set_up: false,
  allow_stat_box: false,
  is_admin: false,
  game_setup:"",
  games: ['All Games', '2D','3D', 'STL-SILAY', '2D-STL-ILOILO-CITY', '3D-STL-ILOILO-CITY', 'STL-ILOILO-PROVINCE',],
  open_draws:[],
  betlimit: 10,
  account_transactions: []
  }
  