<template>
    <v-dialog
    v-model="show"
    persistent
    width="300">
   
    <v-card color="light_gray">
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-cash</v-icon><span class="va-underline">{{type}}</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text class="pa-4 def-font">
               <v-form
                ref="form"
                v-model="valid"
                lazy-validation>
                <v-text-field 
                type="number"
                    v-model="entry.amount"
                    label="Enter Amount"
                    dense
                    outlined
                    :rules="nameRules"
                    required
                />
                
               </v-form>
               <v-divider/>
          </v-card-text>
          <v-card-actions>
              <small v-if="ISMAX" style="color: red;">Amount is too high!</small>
              <v-spacer/>
              <v-btn small color="secondary" v-if="!ISMAX"  @click="validate('add_line')">
                 Add line
              </v-btn>
          </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
export default {
    mixins: [API_MIXINS],
  props: {
      show:{
          type: Boolean,
          default: false
      },
      type: {
          type: String,
          default: ''
      },
      draw: {
          type: Object,
          default:()=>{}
      }
  },
  data () {
      return {
          valid: true,
          entry:{},
           nameRules: [
            v => !!v || '*Required'
        ],
      }
    },
  watch: {
     show(val) {
         if(val) {
             this.entry = {}
         }
     }
  },
  computed:{
    ISMAX(){
        if(this.$IsNum(this.entry.amount) != 0) {
            var win_amount = this.$IsNum(this.entry.amount) * this.$IsNum(this.draw.winning_amount)
            if(win_amount > this.$IsNum(this.draw.fund)) {
                return true
            }
        }
        return false
    }
  },
  methods: {
   emitToParent (action) {
      this.$emit('DialogEvent', {action:action})
    },
    validate(action){
        if (this.$refs.form.validate()) {
            this.$emit('DialogEvent', {action:action, amount:this.entry.amount})
        }
    }
  }
}
</script>
