<template>
    <div>
   
    <v-card dense>
        <v-toolbar>
             <v-icon left color="secondary">mdi-cash-register</v-icon> CASH REPORTS
           <v-spacer/>
                 <span style="width: 350px; margin-top: 24px">
                    <v-autocomplete
                        v-model="account"
                        label="Search Agent"
                        :items="AGENTS"
                        item-text="fullname"
                        dense
                        filled
                        outlined
                        return-object
                    >
                     <template v-slot:item="data">
                         <v-list-item-content>
                             <v-list-item-title class="subtitle-1">{{data.item.fullname}} </v-list-item-title>
                            <v-list-item-subtitle ><span style="color:#F96332;">{{data.item.area_name}},{{data.item.municipal}},{{data.item.barangay}}</span></v-list-item-subtitle>
                         </v-list-item-content>
                     </template>
                    </v-autocomplete>
                </span>
                <!-- <span style="width: 200px; margin-top: 24px;"> 
                    <v-menu
                        v-model="from_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="From Date"
                                 :value="from_date"
                                 filled
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date" no-title scrollable @input="from_menu=false" />
                    </v-menu>
               </span>
                 -->
               
        </v-toolbar>
        <v-card-text>
            <p>
                Agent: {{account.fullname}}
            </p>
             <v-progress-linear
                v-if="loading"
                indeterminate
                color="teal"
                ></v-progress-linear>
            <table class="va-table def-font" id="acccount-table">
                <!-- <tr>
                     <td >Municipal: {{municipal}}</td>
                     <td colspan="5">Barangay: {{barangay}}</td>
                </tr> -->
                <tr>
                    <th>DATE</th>
                    <th>STATUS</th>
                    <th>TYPE</th>
                    <th>REMARKS</th>
                    <th class="text-right">AMOUNT</th>
                </tr>
                 <tr v-for="(item,index) in FILTERITEMS" :key="index" >
                    <td class="border_bot">{{item.fullname}} </td>
                    <td class="text-right border_bot">{{$money(item.gross)}}</td>
                </tr>
            </table>
        </v-card-text>     
    </v-card>
  </div>
</template>

<script>

export default {
  props: {
      show:{
          type: Boolean,
          default: false
      },
      area:{
          type: Object,
          default: ()=>{}
      }
  },
  data: () => ({
        loading: false,
        from_menu: false,
        to_menu: false,
        from_date: '',
        to_date: '',
        municipal:'',
        barangay:'',
        account: {},
        items:[],
        users:[],
        areas:[]
    }),
    created() {
        this.getUsers()
    },
    computed:{
         TOTALWIN() {
            var total = this.FILTERITEMS.reduce((res,item)=>res += this.$IsNum(item.win),0)
            return total
        },
         TOTALPAYOUT() {
            var total = this.FILTERITEMS.reduce((res,item)=>res += this.$IsNum(item.payout),0)
            return total
        },
        FILTERITEMS(){
            if(this.municipal == '' && this.barangay == '' && this.account==0) {
                return this.items
            }
                 var arr = this.items.reduce((res,item)=>{
                     if(this.municipal != '' && this.barangay == '') {
                        if(item.municipal ==  this.municipal) {
                            res.push(item)
                        }
                     } else if(this.municipal != '' && this.barangay != '') {
                            if(item.barangay ==  this.barangay) {
                            res.push(item)
                        }
                     } else if(this.account != 0) {
                            if(item.account ==  this.account) {
                            res.push(item)
                        }
                     }
                     return res
                 }
                 ,[])
         return arr
        },
        BARANGAYS(){
                var selected_areas = this.area.Areas != undefined?this.area.Areas:[]
             var arr = selected_areas.reduce((res,item)=>{
                       if(item.name ==  this.municipal) {
                           res = item.barangays
                        }
                     return res
                 },[])
         return arr
        },
        AGENTS(){
             var arr = this.users.reduce((res,item)=>{
                       if(item.role ==  "Agent") {
                           
                           res.push(item)
                        }
                     return res
                 },[])
            return arr
        },
        TOTALGROSS() {
            var total = this.FILTERITEMS.reduce((res,item)=>res += this.$IsNum(item.gross),0)
            return total
        }
    },
  methods: {
      emitToParent (action) {
      this.$emit('DialogEvent', action)
    },
        getTransactions(){
            this.loading = true
            this.items =[]
            var param = {}
            param.from_date = this.from_date
            param.to_date = this.to_date
            param.Method = "peraccount"
            param.account = this.area.id
             this.$http.post("report/transactions",param).then(response => {
                response.data.transactions !=null?this.items = response.data.transactions:this.items =[]
                 this.loading = false
          }).catch(e => {
            console.log(e.data)
          })
        },
        TotalGross(area){
            var total = this.items.reduce((res,item)=>{ 
                if(area == item.area_name) {
                        res+=this.$IsNum(item.gross)
                }
                return res
            }, 0)
            return total
        },
        getUsers: function() { 
            console.log("getUsers")
        this.$http.post("user/list", {}).then(response => {
            response.data.data != null?this.users = response.data.data:this.users =[]
        }).catch(e => {
        console.log(e.data)
        })
     },
        dlToCsv(){
            this.DownloadToCsv("acccount-table", 'Per-account-' + this.from_date + '-to-' + this.to_date)
        }
  }
}
</script>
