import Vue from 'vue'

Vue.prototype.$money = function (amount) {
  if (!amount) { amount = 0 }
  if (isNaN(amount)) {
    amount = 0
  }
  amount = parseFloat(amount).toFixed(2)
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
Vue.prototype.$commas = function (amount) {
  if (!amount) { amount = 0 }
  if (isNaN(amount)) {
    amount = 0
  }
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
Vue.prototype.$IsNum = function (input) {
  if (isNaN(parseFloat(input)) || input == null) {
    return 0
  }
  input = parseFloat(input).toFixed(2)
  return parseFloat(input)
}
Vue.prototype.$printThis = function () {
    window.print()
}
Vue.prototype.$groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

Vue.prototype.$StatusColor = function (status) {
  switch(status){
    case 'Drawn':
      return "#313131"
    case 'Ready':
      return "info"
    case 'Close':
        return "warning"
    case 'Open':
      return "success"
      case 'claimed':
        return "success"
        case 'unclaimed':
          return "warning"
          case 'Void':
            return "#999"
    default:
        return "info"
  }    
}
