<template>
    <div v-if="show">
   
    <v-card dense>
        <v-toolbar>
            CURRENT TRANSACTIONS
           <v-spacer/>
           As of : {{ this.$moment().format('MMM DD, YYYY')}}
        </v-toolbar>
        <v-card-text>
             <v-progress-linear
                v-if="loading"
                indeterminate
                color="teal"
                ></v-progress-linear>
            
            <table class="va-table def-font" id="over-table">
                <tr>
                    <!-- <th>DRAW ID</th> -->
                    <th>GAME</th>
                    <!-- <th>STATUS</th> -->
                    <th>DRAW</th>
                    <th class="text-right">GROSS</th>
                    <th class="text-right">UNIT HITS</th>
                    <th class="text-right">AMOUNT HITS</th>
                </tr>
                 <tr v-for="(item,index) in items" :key="index" >
                     <!-- <td class="border_bot">{{item.draw_id}} </td> -->
                    <td class="border_bot">{{item.game}} </td>
                    <!-- <td class="border_bot">{{item.status}} </td> -->
                    <td class="border_bot">{{item.draw_time}}</td>
                    <td class="text-right border_bot">{{$money(item.gross)}}</td>
                    <td class="text-right border_bot">{{$money(item.amount_of_winning)}}</td>
                    <td class="text-right border_bot">{{$money(item.payout)}}</td>
                </tr>
                <tr>
                    
                     <th  class="text-right" style="background: #efefef;" colspan="2">TOTAL:</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALGROSS)}}</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALHITS)}}</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALPAYOUT)}}</th>
                </tr>
               
            </table>
        </v-card-text>     
    </v-card>
  </div>
</template>

<script>

export default {
  props: {
      show:{
          type: Boolean,
          default: false
      }
  },
  data: () => ({
        loading: false,
        from_menu: false,
        to_menu: false,
        from_date: '',
        to_date: '',
        items:[]
       
    }),
  created(){
        this.getTransactions()
  },
    computed:{
        TOTALGROSS() {
            var total = this.items.reduce((res,item)=>res += this.$IsNum(item.gross),0)
            return total
        },
         TOTALHITS() {
            var total = this.items.reduce((res,item)=>res += this.$IsNum(item.amount_of_winning),0)
            return total
        },
         TOTALPAYOUT() {
            var total = this.items.reduce((res,item)=>res += this.$IsNum(item.payout),0)
            return total
        }
    },
  methods: {
        getTransactions(){
            this.loading = true
            this.items =[]
            var param = {}
            param.from_date = this.$moment().format('YYYY-MM-DD')
            param.to_date =  param.from_date
            param.Method = "perdraw"
             this.$http.post("report/transactions",param).then(response => {
                response.data.transactions !=null?this.items = response.data.transactions:this.items =[]
                console.log(33)
                this.loading = false
          }).catch(e => {
            console.log(e.data)
          })
        },
        dlToCsv(){
            this.DownloadToCsv("over-table", 'Per-draw-' + this.from_date + '-to-' + this.to_date)
        }
  }
}
</script>
