<template>
    <div v-if="show">
   
    <v-card dense>
        <v-toolbar>
            PER AREA
            <v-spacer/>
               <span style="width: 200px; margin-top: 24px;"> 
                     <v-menu
                        v-model="from_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="From Date"
                                 :value="from_date"
                                 filled
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date" no-title scrollable @input="from_menu=false" />
                    </v-menu>
               </span>
                <span style="width: 200px; margin-top: 24px;" class="ml-2"> 
                     <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="To Date"
                                 :value="to_date"
                                 filled
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, getTransactions()" />
                    </v-menu>
               </span>
               <v-icon right color="primary" @click="dlToCsv()">mdi-download</v-icon>
        </v-toolbar>
        <v-card-text>
             <v-progress-linear
                v-if="loading"
                indeterminate
                color="teal"
                ></v-progress-linear>
            <table class="va-table def-font" id="over-table">
                <tr>
                    <th>AREA</th>
                    <th>GAME</th>
                    <th class="text-right">GROSS</th>
                    <th class="text-right">Win</th>
                    <th class="text-right">Payout</th>
                </tr>
                 <tr v-for="(item,index) in items" :key="index" >
                     <td class="border_bot">{{item.area_name}} </td>
                    <td class="border_bot">{{item.game}} </td>
                    <td class="text-right border_bot">{{$money(item.gross)}}</td>
                    <td class="text-right border_bot">{{$money(item.win)}}</td>
                    <td class="text-right border_bot">{{$money(item.payout)}}</td>
                  
                </tr>
                <tr>
                    <th  class="text-right" style="background: #efefef;" colspan="2">TOTAL:</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALGROSS)}}</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALWIN)}}</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALPAYOUT)}}</th>
                </tr>
                 <tr>
                    <th colspan="3">SUMMARY</th>
                </tr>
                 <tr>
                    <th style="background: #efefef;">AREA</th>
                    <th style="background: #efefef;" class="text-right" colspan="2">GROSS</th>
                     <th style="background: #efefef;" class="text-right" >WIN</th>
                      <th style="background: #efefef;" class="text-right">PAYOUT</th>
                </tr>
                <tr v-for="(area,index) in areas" :key="index+'-area'" >
                     <td class="border_bot">{{area.name}}  <v-icon @click="daterange= {from_date: from_date, to_date:to_date}, selected_area=area, per_account=true" right small color="success">mdi-open-in-new</v-icon> </td>
                    <td class="text-right border_bot" colspan="2">{{$money(TotalGross(area.name))}}</td>
                     <td class="text-right border_bot">{{$money(TotalWin(area.name))}}</td>
                      <td class="text-right border_bot">{{$money(TotalPayout(area.name))}}</td>
                </tr>
            </table>
        </v-card-text>     
    </v-card>
    <report-per-account :show="per_account" :area="selected_area" :daterange="daterange" @DialogEvent="accEvent"/>
  </div>
</template>

<script>

export default {
  props: {
      show:{
          type: Boolean,
          default: false
      }
  },
  data: () => ({
      daterange:{},
        per_account: false,
        loading: false,
        from_menu: false,
        to_menu: false,
        from_date: '',
        to_date: '',
        items:[],
        areas:[],
        selected_area: {}
    }),
    computed:{
        TOTALGROSS() {
            var total = this.items.reduce((res,item)=>res += this.$IsNum(item.gross),0)
            return total
        },
         TOTALWIN() {
            var total = this.items.reduce((res,item)=>res += this.$IsNum(item.win),0)
            return total
        },
         TOTALPAYOUT() {
            var total = this.items.reduce((res,item)=>res += this.$IsNum(item.payout),0)
            return total
        }
    },
  methods: {
      accEvent() {
          this.per_account = false
      },
        getTransactions(){
            this.loading = true
            this.items =[]
            var param = {}
            param.from_date = this.from_date
            param.to_date = this.to_date
            param.Method = "perarea"
             this.$http.post("report/transactions",param).then(response => {
                response.data.transactions !=null?this.items = response.data.transactions:this.items =[]
                 this.getAreas()
          }).catch(e => {
            console.log(e.data)
          })
        },
        getAreas(){
            this.areas = []
           this.$http.get("area/list",).then(response => {
            response.data.areas != null?this.areas = response.data.areas:this.areas =[]
            this.loading = false
         }).catch(e => {
            console.log(e.data)
          })
      },
      TotalGross(area){
            var total = this.items.reduce((res,item)=>{ 
                if(area == item.area_name) {
                        res+=this.$IsNum(item.gross)
                }
                return res
            }, 0)
            return total
        },
        TotalWin(area){
            var total = this.items.reduce((res,item)=>{ 
                if(area == item.area_name) {
                        res+=this.$IsNum(item.win)
                }
                return res
            }, 0)
            return total
        },
        TotalPayout(area){
            var total = this.items.reduce((res,item)=>{ 
                if(area == item.area_name) {
                        res+=this.$IsNum(item.payout)
                }
                return res
            }, 0)
            return total
        },
        dlToCsv(){
            this.DownloadToCsv("over-table", 'Per-draw-' + this.from_date + '-to-' + this.to_date)
        }
  }
}
</script>
