<template>
    <div v-if="show">
   
    <v-card dense>
        <v-toolbar>
           GAME ACCOUNTS SUMMARY
            <v-spacer/>
               <span style="width: 200px; margin-top: 24px;"> 
                     <v-menu
                        v-model="from_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="From Date"
                                 :value="from_date"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date" no-title scrollable @input="from_menu=false" />
                    </v-menu>
               </span>
                <span style="width: 200px; margin-top: 24px;" class="ml-2"> 
                     <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="To Date"
                                 :value="to_date"
                                 readonly
                                 filled
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, getTransactions()" />
                    </v-menu>
               </span>
               <v-icon right color="primary" @click="dlToCsv()">mdi-download</v-icon>
        </v-toolbar>
        <v-card-text>
             <v-progress-linear
                v-if="loading"
                indeterminate
                color="teal"
                ></v-progress-linear>
            <p class="text-center">
               GAME ACCOUNTS SUMMARIES<br />
Draw Period: OVERALL<br />
Date Covered: {{$moment(from_date).format('YYYY-MMM-DD')}} - {{$moment(to_date).format('YYYY-MMM-DD')}} 
            </p>
            <div style="width: 100%; overflow: scroll">
            <table class="print_table" id="over-table" style="width: 1900px;">
                
                <tr>
                    <td>DATE</td>
                    <!-- <td>CLUSTER</td> -->
                    <!-- <td>BARANGAY</td>
                    <td>RIDER</td> -->
                    <td>TYPE</td>
                    <td>GEN. COOR</td>
                    <!-- <td>AGENT</td> -->
                    <!-- <td>COMM.(%)</td> -->
                    <td>USERNAME</td>
                    <!-- <td class="text-right" style="background: #dedede">DRAW 1</td>
                    <td class="text-right">COMM</td>
                    <td class="text-right">NET</td>
                    <td class="text-right"> HITS</td>
                    <td class="text-right" style="background: #E5F6F4">TOTAL</td>
                    <td class="text-right" style="background: #dedede">DRAW 2</td>
                    <td class="text-right"> COMM</td>
                    <td class="text-right">NET</td>
                    <td class="text-right">HITS</td>
                    <td class="text-right" style="background: #E5F6F4">TOTAL</td>
                    <td class="text-right" style="background: #dedede">DRAW 3</td>
                    <td class="text-right">COMM</td>
                    <td class="text-right">NET</td>
                    <td class="text-right">HITS</td>
                    <td class="text-right" style="background: #E5F6F4">TOTAL</td>
                <td class="text-right">OVERALL GROSS</td> -->
                <!-- <td class="text-right">OVERALL COMM</td> -->
                <td class="text-right">OVERALL NET</td>
                <td class="text-right">OVERALL HITS</td>
                <td class="text-right">NET GROSS</td>
                </tr>
               <tr v-for="(account, index) in accounts" :key="index+'-account'">
                    <td>{{account.date}}</td>
                    <!-- <td>{{account.clustername?account.clustername:'No Cluster'}}</td> -->
                    <!-- <td>{{account.barangay}}</td>
                    <td>{{account.rider}}</td> -->
                    <td>{{account.role}}</td>
                    <td>{{account.coordinator}}</td>
                    <!-- <td>{{account.agent}}</td> -->
                    <!-- <td>{{account.commission}}</td> -->
                   <td>{{account.username}}</td>
                   
                <!-- <template v-for="n in (3-get_summary(account.account_id, account.date).length)">
                        <td class="text-right" :key="n + '-1f'">--</td>
                        <td class="text-right" :key="n + '-2f'">--</td>
                        <td class="text-right" :key="n + '-3f'">--</td>
                        <td class="text-right" :key="n + '-4f'">--</td>
                        <td class="text-right" :key="n + '-5f'">--</td>
                </template> -->
                  <template v-for="(data,o) in over_all_data(account)">
                    <td class="text-right" :key="o+'-o-g'" :class="data<0?'text-error':''">{{ $money(data)}}</td>
                  </template>
                </tr>
            </table>
            </div>
        </v-card-text>     
    </v-card>
  </div>
</template>

<script>

export default {
  props: {
      show:{
          type: Boolean,
          default: false
      }
  },
  data: () => ({
        loading: false,
        from_menu: false,
        to_menu: false,
        from_date: '',
        to_date: '',
        accounts:[],
        games:[]
       
    }),
    computed:{
        GAMESUMMARY() {
            var byAccounts = this.$groupBy('account')
            return byAccounts(this.games)
        }
    },
  methods: {
      convertSeq(str){
            return this.$IsNum(str.replace("draw ", "")) -1
      },
      over_all_data(account){
           var data = this.get_summary(account.account_id, account.date)
           var  gross = data.reduce((res,item)=>{
                res += this.$IsNum(item.gross)
                return res 
            }, 0)
              var  hits = data.reduce((res,item)=>{
                res += this.$IsNum(item.hits_amount)
                return res 
            }, 0)
            //  var  comm = data.reduce((res,item)=>{
            //     res += this.$IsNum(item.gross) * (this.$IsNum(account.commission)/100)
            //     return res 
            // }, 0)
            //  var  net = data.reduce((res,item)=>{
            //     res += this.$IsNum(item.gross) - (this.$IsNum(item.gross) * (this.$IsNum(account.commission)/100))
            //     return res 
            // }, 0)
             var  revenue = data.reduce((res,item)=>{
                res +=this.$IsNum(item.gross) - this.$IsNum(item.hits_amount)
                return res 
            }, 0)
            return [gross,hits, revenue]
      },
      get_total(summary){
          return this.$IsNum(summary.gross) - this.$IsNum(summary.hits_amount)
      },
      get_summary(account, drawDate){
        console.log(drawDate)
        for (const [key, value] of Object.entries(this.GAMESUMMARY)) {
            if(key == account)
                return this.get_value(value, drawDate)
            }
       return []
      },
        get_value(value, drawDate){
            var sum = value.reduce((res,item)=>{
                  if(item.draw_date == drawDate){
                      res.push(item)
                  }  
                  return res
            },[])
            
            var first_draw =  sum.reduce((res,item)=>{
                  if(item.draw_seq == 'draw 1'){
                      res = item
                  }  
                  return res
            },{draw_seq:'draw 1'})
             var second_draw =  sum.reduce((res,item)=>{
                  if(item.draw_seq == 'draw 2'){
                      res = item
                  }  
                  return res
            },{draw_seq:'draw 2'})
             var third_draw =  sum.reduce((res,item)=>{
                  if(item.draw_seq == 'draw 3'){
                      res = item
                  }  
                  return res
            },{draw_seq:'draw 3'})
            return [first_draw,second_draw,third_draw]
        },
        getTransactions(){
            this.loading = true
            this.items =[]
            var param = {}
            param.from_date = this.from_date
            param.to_date = this.to_date
            param.Method = "game_account_summary"
             this.$http.post("report/transactions",param).then(response => {
                response.data.games !=null?this.games = response.data.games:this.games =[]
                 response.data.accounts !=null?this.accounts = response.data.accounts:this.accounts =[]
                this.loading = false
                     console.log(this.games)
                console.log(this.accounts)
          }).catch(e => {
            console.log(e.data)
          })
        },
        dlToCsv(){
            this.DownloadToCsv("over-table", 'Game-account-summary-' + this.from_date + '-to-' + this.to_date)
        }
  }
}
</script>
