<template>
  <div class="small">
          <bar-chart :height="300" :chart-data="CHARTDATA" style="background: #19365A; padding: 7px; color: #fff;" dark></bar-chart>
  </div>
</template>

<script>
  import BarChart from '@/plugins/BarChart.js'

  export default {
      components: {
        BarChart
      },
        data: () => ({
          labels: [],
          data:[],
          betlimit:[],
          datacollection:{},
          gradient: null,
      }),
      props: {
        rawdata: {
          type: Array,
          default: function(){ return []}
        }
      },
    watch: {
      rawdata() {
        this.exportData()
        this.fillData()
        
      }
    },
    computed: {
      CHARTDATA() {
        return this.datacollection
      },
      BGCOLOR() {
        var canvas = document.getElementById('line-chart')
         var gradient = canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
        gradient.addColorStop(0, "rgba(128, 182, 244, 0)");
        gradient.addColorStop(1, "rgba(255, 255, 255, 0.24)");
        return gradient
      }
    },
    methods: {
      exportData() {
        this.labels = []
        this.data = []
       //  this.betlimit=[]
        for (const item of this.rawdata) {
              this.labels.push(item.req_date)
              this.data.push(item.amount)
             // this.betlimit.push(item.limit)
          }
    
      },
      fillData () {
      //  console.log(this.betlimit)
        this.datacollection = {
          labels:this.labels,
          datasets: [
            {
              label: "Buys",
              backgroundColor: '#F89111',
              // borderColor: "#FFF",
              // pointBorderColor: "#FFF",
              // pointBackgroundColor: "#FFF",
              // pointBorderWidth: 2,
              // pointHoverRadius: 4,
              // pointHoverBorderWidth: 1,
              // pointRadius: 2,
              // borderWidth: 0.5,
              // fill: true,
              data: this.data
            },
          ]
        }
     
      }
    }
  }
</script>

<style>
  .small {
    height: 300px;
  }
</style>