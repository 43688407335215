import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: "#414E66",//'#AC1F62',
            secondary:  '#414E66',
            tertiary: '#F89111',
            accent: '#AC1F62',
            error: '#f55a4e',
            info: '#00d3ee',
            success: "#5cb860",//#5cb860',
            warning: '#ffa21a',
            light: '#f0f0f0',
          },
        },
      }
});
