<template>
    <v-dialog
    v-model="show"
    persistent
    width="780">
   
    <v-card color="light_gray">
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-map-marker-radius</v-icon><span class="va-underline">{{state=='new'?'New':'Edit'}} Cluster</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text class="pa-4 def-font">
               <v-form
                ref="form"
                v-model="valid"
                lazy-validation>
                <v-text-field 
                    v-model="entry.name"
                    label="Cluster Name"
                    dense
                    outlined
                    :rules="nameRules"
                                    required
                />
                 <v-row>
                     <v-col cols="12">
                         <v-toolbar
                         dense
                         >
                         ZONES
                         <v-spacer />
                         <v-icon right color="success" @click="action_type='new_barangay', text_header='Barangay',textform=true">mdi-plus-box</v-icon> 
                         </v-toolbar>
                          <v-data-table
                    class="va-table mt-2"
                    dense
                    hide-default-header
                    disable-pagination
                    hide-default-footer
                    :items="entry.areas != null?entry.areas:[]"
                    >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                               <td>  <v-icon small color="error" @click="selectedIndex=index,message='Remove Zone?', remove_type='remove_barangay', confirm=true"  left>mdi-delete</v-icon>  {{ item.name }}</td>
                             
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
                     </v-col>
                     
                 </v-row>
               </v-form>
               <v-divider/>
          </v-card-text>
          <v-card-actions>
              <!-- <v-btn small color="error" v-if="state=='update'" @click="Remove()">
                  Remove Bermuda
              </v-btn> -->
              <v-spacer/>
              <v-btn small color="secondary" @click="validate()">
                  {{state=='new'?'ADD':'SAVE'}} 
              </v-btn>
          </v-card-actions>
    </v-card>
    <va-text-form :show="textform" :header="text_header" :type="action_type" @DialogEvent="tfEvent"/>
    <va-confirm :show="confirm" :message="message" cancelbtn="cancel" okbtn="remove" @ConfirmEvent="cfEvent"/>
  </v-dialog>
</template>

<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
export default {
    mixins: [API_MIXINS],
  props: {
      show:{
          type: Boolean,
          default: false
      },
      state: {
          type: String,
          default: 'new'
      },
      area: {
          type: Object,
          default:()=>{}
      }
  },
  data () {
      return {
          textform: false,
          selectedIndex:null,
          message:'',
          confirm: false,
          action_type: '',
          remove_type: null,
          text_header:"",
          valid: true,
          municipal:null,
          entry:{},
           nameRules: [
            v => !!v || '*Required'
        ],
      }
    },
  watch: {
     show(val) {
         if(val) {
             if(this.state!='new'){
                 this.entry = this.area
             } 
         } else { 
             this.entry = {}
             this.municipal=null
     }
     }
  },
  computed:{
    
  },
  methods: {
    cfEvent(data) {
       this.confirm = false
       if("ok"==data){
            if(this.remove_type == "remove_barangay") {
                this.remove_municipality(this.selectedIndex)
            } 
       }
    },
   emitToParent (action) {
      this.$emit('DialogEvent', action)
    },
    tfEvent(data) {
        this.textform = false
        if(data.action != 'close'){
            if(this.action_type == 'new_barangay') {
                var new_barangay = {name: data.action.trim()}
                this.entry.areas != null?this.entry.areas.push(new_barangay): this.entry.areas = [new_barangay]
            }
        }
    },
   
    validate(){
        if (this.$refs.form.validate()) {
          this.Save()
        }
    },
    remove_municipality(index) {
        this.entry.areas.splice(index,1)
       // this.Save()
    },
    Remove(){
        this.$http.post("area/delete", this.entry).then(response => {
            response.data.status?this.VA_ALERT('success',response.data.message):this.VA_ALERT('error',response.data.message)
        this.$emit('DialogEvent', 'close')
        }).catch(e => {
            console.log(e)
        })
    },
    Save(){
        var method = this.state == 'new'?'area/add':'area/update'
        this.entry.name = this.entry.name.trim()
        this.$http.post(method, this.entry).then(response => {
            response.data.status?this.VA_ALERT('success',response.data.message):this.VA_ALERT('error',response.data.message)
        this.$emit('DialogEvent', 'close')
        }).catch(e => {
            console.log(e)
        })
    }
  }
}
</script>
