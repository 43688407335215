<template>
    <v-dialog
    v-model="show"
    persistent
    width="320">
   
    <v-card>
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-ticket-confirmation</v-icon><span class="va-underline">TICKET</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
        <v-card-text class="pa-5">
                {{ticket.game}} <br />
                Draw ID: {{ticket.draw_id}}<br />
                Draw Datetime: {{ticket.draw_date}}<br />
                Trans #: {{ticket.id}}<br />
                Code: {{ticket.code}}<br />
                Trans Date: {{ticket.transaction_date | moment('lll')}}<br />
                  <template v-for="(line, index) in ticket.Lines">
                    <div :key="index +'-i'" style="padding: 2px; border-bottom: 1px solid #eee;">
                      ({{line.line_type}})
                       <template v-if="ticket.game == 'SWER3'">
                            <span style="float: right;"> {{$money(line.line_amount)}}</span>
                            {{line.num1}} - {{line.num2}} - {{line.num3}}
                        </template>
                        <template v-if="ticket.game == 'PICK3'">
                            <span style="float: right;"> {{$money(line.line_amount)}}</span>
                            {{line.num1}} - {{line.num2}} - {{line.num3}}
                        </template>
                         <template v-if="ticket.game == 'SWER2' || ticket.game == 'STLPARES' || ticket.game == 'STLLOCAL'">
                            <span style="float: right;"> {{$money(line.line_amount)}}</span>
                            {{line.num1}} - {{line.num2}}
                        </template>
                        <template v-if="ticket.game == '3D'">
                            <span style="float: right;"> {{$money(line.line_amount)}}</span>
                            {{line.num1}} - {{line.num2}} - {{line.num3}}
                        </template>
                         <template v-if="ticket.game == '3DLOCAL'">
                            <span style="float: right;"> {{$money(line.line_amount)}}</span>
                            {{line.num1}} - {{line.num2}} - {{line.num3}}
                        </template>
                         <template v-if="ticket.game == '2DLOCAL'">
                            <span style="float: right;"> {{$money(line.line_amount)}}</span>
                            {{line.num1}} - {{line.num2}}
                        </template>
                          <template v-if="ticket.game == '2D'">
                            <span style="float: right;"> {{$money(line.line_amount)}}</span>
                            {{line.num1}} - {{line.num2}}
                        </template>
                         <template v-if="ticket.game == '4D'">
                            <span style="float: right;"> {{$money(line.line_amount)}}</span>
                            {{line.num1}} - {{line.num2}} -   {{line.num3}} - {{line.num4}}
                        </template>
                    </div>
                  </template>
                  <div style="height: 30px;">
                  <span style="float: right;">TOTAL: {{$money(TOTAL_AMOUNT)}}</span>
                </div>
                <p class="text-center">
                   <vue-qrcode :value="QR_CODE" />
                </p>

        </v-card-text>      
    </v-card>
  </v-dialog>
</template>

<script>
import VueQrcode from 'vue-qrcode'
export default {
    components: {
    VueQrcode,
  },
  props: {
      show:{
          type: Boolean,
          default: false
      },
      ticket: {
            type: Object,
          default:()=>{}
      }
  },
  computed:{
     TOTAL_AMOUNT(){
       if(this.ticket.Lines == undefined) {
         return 0
       }
        var total = this.ticket.Lines.reduce((a,o)=> a+=this.$IsNum(o.total_amount), 0)
        return total
      },
      QR_CODE(){
          if(this.ticket.id == undefined || this.ticket.id == null) {
              return 'no data'
          } else {
              return this.ticket.id.toString() +","+this.ticket.code
          }
      }
  },
  methods: {
   emitToParent (action) {
      this.$emit('DialogEvent', action)
    }
  }
}
</script>
